import React from "react";
import Link from "next/link";
import Image from "next/image";
import styles from "../styles/Components/Footer.module.scss";

function Footer() {
  const WhatsOn = [
    {
      title: "New year Eve",
      hyperLink: "/",
    },
    {
      title: "Blogs",
      hyperLink: "/",
    },
    {
      title: "@adventureresorts",
      hyperLink: "/",
    },
  ];

  const rides_activities = [
    {
      title: "Go-karting",
      hyperLink: "/rides-and-activities/go-karting",
    },
    {
      title: "Zip Line",
      hyperLink: "/rides-and-activities/zip-line",
    },
    {
      title: "Sky Swinger",
      hyperLink: "/rides-and-activities/sky-swinger",
    },
    {
      title: "Snow Skiing",
      hyperLink: "/rides-and-activities/snow-skiing",
    },
    {
      title: "Bungee Ejection",
      hyperLink: "/rides-and-activities/bungee-ejection",
    },
  ];

  const ananta = [
    // {
    //   title: "Ananta",
    //   hyperLink: "https://ananta.adventureresortkufri.com/",
    // },
    {
      title: "Meeting & Events",
      hyperLink: "https://ananta.adventureresortkufri.com/meeting",
      target: '_blank',
    },
    {
      title: "Wedding & Celebrations",
      hyperLink: "https://ananta.adventureresortkufri.com/wedding",
      target: '_blank',
    },
    {
      title: "Corporate  & MICE",
      hyperLink: "/corporate-tour",
      target: '',
    },
    {
      title: "Schools, Colleges & Universities",
      hyperLink: "/educational-tour",
      target: '',
    },
  ];

  const about = [
    {
      title: "About Us",
      hyperLink: "/about-us",
    },
    {
      title: "Dining",
      hyperLink: "/restaurant",
    },
    {
      title: "Stay",
      target: "_blank",
      hyperLink: "https://www.hoteltwintowers.com",
    },
    {
      title: "Tickets",
      hyperLink: "/tickets",
    },
    {
      title: "Blogs",
      hyperLink: "/blogs",
    },
    {
      title: "Gallery",
      hyperLink: "/gallery",
    },
    {
      title: "Contact Us",
      hyperLink: "/contact-us",
    },
  ];

  const legal = [
    {
      title: "Privacy Policy",
      hyperLink: "/privacy-policy",
    },
    {
      title: "Terms & Conditions",
      hyperLink: "/terms-and-conditions",
    },
    {
      title: "General Instruction",
      hyperLink: "/general-instruction",
    },
    {
      title: "Cancellation & Refund Policy",
      hyperLink: "/cancellation-and-refundpolicies",
    },
  ];

  return (
    <>
      <footer
        className={`${styles.footer_sec} common_padding pb-0`}
        data-speed="1"
      >
        <div className="container">
          <div className="text-center">
            <Link href="/">
              <Image
                src="/icons/footer-logo.svg"
                width={130}
                height={85}
                alt="Logo"
              ></Image>
            </Link>

            <p style={{ color: '#DB2C16', fontSize: '15px', paddingTop: '20px', marginBottom: '5px' }}><strong>Operating Hours:</strong></p>
            <p style={{ color: '#DB2C16', fontSize: '13px', marginBottom: '-12px' }}>Winter: 9:00 AM - 5:30 PM Summer: 9:00 AM - 7:00 PM</p>
          </div>

          {/* <div className="col-md-8"> */}
          <div className={`${styles.footer_upper} row`}>
            {/* <div className={`${styles.footer_column} col`}>
                            <p>What’s On</p>
                            <ul>
                                {WhatsOn.map((item, index) => (
                                    <li key={index}>
                                        <Link href={item.hyperLink}>
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div> */}
            <div className={`${styles.footer_column} col col-6 col-sm-6 col-md-3 col-lg-3`}>
              <p>Popular Activities</p>
              <ul>
                {rides_activities.map((item, index) => (
                  <li key={index}>
                    <Link href={item.hyperLink}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`${styles.footer_column} col col-6 col-sm-6 col-md-3 col-lg-3`}>
              <p>Events and Group Visits</p>
              <ul>
                {ananta.map((item, index) => (
                  <li key={index}>
                    <Link href={item.hyperLink} target={item.target}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`${styles.footer_column} col col-6 col-sm-6 col-md-3 col-lg-3`}>
              <p>Quick Links</p>
              <ul>
                {about.map((item, index) => (
                  <li key={index}>
                    <Link target={item.target} href={item.hyperLink}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`${styles.footer_column} col col-6 col-sm-6 col-md-3 col-lg-3`}>
              <p>Legal</p>
              <ul>
                {legal.map((item, index) => (
                  <li key={index}>
                    <Link href={item.hyperLink}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* </div> */}

          <div className={styles.footer_bottom}>
            <div className={styles.footer_bottom_left}>
              <p>&#169; Kufri Fun Campus Pvt. Ltd. All Rights Reserved</p>
            </div>
            <div className={styles.footer_bottom_center}>
              <Link
                href="https://www.youtube.com/@adventureresorts"
                target="_blank"
              >
                <Image
                  src="/icons/youtube.svg"
                  width={20}
                  height={20}
                  className="mx-2"
                  alt="Youtube Icon"
                ></Image>
              </Link>
              <Link
                href="https://www.facebook.com/profile.php?id=100063787581492"
                target="_blank"
              >
                <Image
                  src="/icons/fb.svg"
                  width={20}
                  height={20}
                  className="mx-2"
                  alt="Facebook Icon"
                ></Image>
              </Link>
              <Link
                href="https://www.instagram.com/adventureresorts/"
                target="_blank"
              >
                <Image
                  src="/icons/insta.svg"
                  width={20}
                  height={20}
                  className="mx-2"
                  alt="Instagram Icon"
                ></Image>
              </Link>

            </div>
            <div className={styles.footer_bottom_right}>
              <p>

                <span className={styles.footer_p}> Powered By:</span>
                <Link
                  href="https://unmeteredtechnologies.com/"
                  target="_blank"
                  rel="nofollow"
                >
                  <Image
                    src="/icons/unmetered-logo.gif"
                    alt="Company Logo" width={95} height={20} />
                </Link>
              </p>
            </div>
          </div>
          {/* <p className='text-center col-md-8 m-auto d_none_mob'>Lorem ipsum dolor sit amet consectetur. Urna integer morbi ultrices imperdiet morbi mattis. Sed neque lacus nulla dignissim adipiscing. Urna integer morbi ultrices imperdiet morbi mattis Sed neque lacus.Urna integer morbi ultrices imperdiet morbi mattis.</p> */}
        </div>

        

          </footer>
          </>
            );
}
            export default Footer;